.footer {
    /* box-shadow: 4px 0px 12px #adadad;
    margin-top: 1rem;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height:6vh;
    z-index: 8;
    background: #ffffff; */
    box-shadow: 4px 0px 12px #adadad;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 8;
}

.date {
    font-size: small;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.footer-child{
    width: 100%;
    height: 100%;
    background-color: white;
}