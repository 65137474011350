.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    margin-left: 12px;
}

.add-more-candidate-form-header{
    display: flex;
    justify-content: space-between;
}

.amc-form-header-inner{
    display: flex;
}

.add-more-icon{
    width: 100%!important;
    height: 100%!important;
    margin: 0!important;
}

.add-more-fields, .input-center{
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-right{
    display: flex;
    justify-content: flex-end;
}

.input-right div{
    padding-right: 5px
}

.add-more-candidate-form .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input{
    height: 0.95em;
}