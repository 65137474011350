.reschedule {
  margin: 10px 0;
  padding: 5px;
  border: 1px solid rgb(223, 212, 212);
  /* font-weight: 900; */
}

.abc {
  font-weight: 600;
}

.reschedule-loader-align {
  display: flex;
}

.archive-btn1 {
  margin-top: -3px;
}

.archive-btn2 {
    margin-top: -5px;
  }
  

