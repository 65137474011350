.NoData {
    z-index: -9;
}

@media screen and (max-width:1500px) {
    .main_dash {
        /* overflow-x: scroll; */
        /* overflow-y: scroll; */
        padding-left: 10px;
        padding-right: 10px;

    }
}

.zoom {
    cursor: pointer;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #58b4ff;
    box-shadow: 2px;
    border-radius: 2px;
    border: 0.25px solid #58b4ff;
    width: 120px;
    cursor: pointer;
}

.interview-video-player {
    width: 100%;
    height: 100%;
    /* height: 200px; */
    border-radius: 7px;
}
.jd-video-card {
    width: 50px;
    height: 50px;
    background: #c4c4c4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.page_count.css-1nrlq1o-MuiFormControl-root {
    min-width: 78px;
}

.page_count {
    display: flex;
}

.filter-show-btn {
    width: 120px;
    height: 40px;
    background: transparent;
}

.center:hover {
    background-color: #f8f8f8;

}

/* --------------------------------------- */
.dashboard-ui-detail-card {
    overflow: scroll;
    overflow-y: hidden;
}

.dashboard-ui-detail-card::-webkit-scrollbar {
    width: 10px;
    height: 5px;
    background-color: #9fd3e7;
}

.dashboard-ui-detail-card::-webkit-scrollbar-thumb {
     background-color:rgb(154, 142, 142);
    border: 3px ;
    border-radius: 9px;
    background-clip: content-box;
}


.d-ui-card-outer {
    width: 600px
}

.d-ui-card-inner-1 {
    width: 15%;
}

.d-ui-card-inner-2 {
    width: 70%;
}


.d-ui-card-inner-3 {
    width: 15%;
}


video.interview-video-player[poster] {
    object-fit: fill;
    padding: 4px;
}


@media screen and (max-width:1200px) {
    .d-ui-card-inner-1 {
        width: initial !important;
    }

    .d-ui-card-inner-2 {
        width: initial !important;
    }

    .d-ui-card-inner-3 {
        width: initial !important;
    }


}

@media screen and (min-width: 900px) and (max-width: 1200px) {
    .d-ui-card-outer {
        width: 500px;
    }
}


@media screen and (max-width:899px) {
    .d-ui-card-inner-1 {
        width: 15% !important;
    }

    .d-ui-card-inner-2 {
        width: 70% !important;
    }


    .d-ui-card-inner-3 {
        width: 15% !important;
    }

}


@media screen and (max-width:850px) {
    .d-board-search-box {
        width: 35% !important;
    }

}


/* @media screen and (max-width:750px) {
    .dashboard-filter-head {
        font-size: 19px !important;
        margin: 0 !important;
    }

    .filter-divider {
        margin-top: -41px !important;
    }
} */


@media screen and (max-width: 680px) {
    .multiple-videos-section {
        width: calc(93% + 16px) !important;
    }
}

@media screen and (max-width: 670px) {
    .d-board-search-box {
        margin-right: 59px !important;
        margin-left: 29px !important;
    }
}

@media screen and (max-width: 650px) {
    .multiple-videos-section {
        width: calc(88% + 16px) !important;
    }
}


@media screen and (max-width: 600px) {
    .d-board-search-box {
        margin-right: 112px !important;
        margin-left: 25px !important;
    }

    .multiple-videos-section {
        width: calc(82% + 16px) !important;
    }
}




@media screen and (max-width: 550px) {
    .filter-container-area {
        right: 23px !important;
    }
}



@media screen and (max-width: 550px) {
    .d-board-search-box {
        width: 28% !important;
        margin-right: 153px !important;
        margin-left: 25px !important;
    }

    .multiple-videos-section {
        width: calc(74% + 16px) !important;
    }
}



@media screen and (max-width: 550px) {
    .filter-show-btn {
        width: 95px;
        height: 34px;
        background: transparent;
    }


    .d-board-search-box {
        height: 38px !important;
    }
}



@media screen and (max-width: 530px) {
    .d-board-search-box {
        margin-right: 180px !important;
    }
}


@media screen and (max-width: 500px) {
    .d-board-search-box {
        margin-right: 212px !important;
    }

    .multiple-videos-section {
        width: calc(67% + 16px) !important;
    }
}


@media screen and (max-width: 480px) {
    .multiple-videos-section {
        width: calc(62% + 16px) !important;
    }
}



@media screen and (max-width: 470px) {
    .d-board-search-box {
        margin-right: 260px !important;
    }
}


@media screen and (max-width: 450px) {
    .multiple-videos-section {
        width: calc(58% + 16px) !important;
    }

    .filter-container-area {
        width: 300px !important;
    }

    .filter-autocomplete-box {
        width: 271px !important;
    }
}


@media screen and (max-width: 420px) {
    .multiple-videos-section {
        width: calc(53% + 16px) !important;
    }
}

@media screen and (max-width: 400px) {
    .filter-show-btn {
        width: 93px !important;
        height: 32px !important;
        font-size: 13px !important;
    }

    .d-board-search-box {
        margin-right: 308px !important;
        height: 35px !important;
    }

    .dashboard-filter-box input::placeholder {
        font-size: 13px;
    }

    .dashboard-filter-box-btn {
        padding: 6px !important;
    }

    .multiple-videos-section {
        width: calc(50% + 16px) !important;
    }
}


@media screen and (max-width: 370px) {
    .multiple-videos-section {
        width: calc(45.5% + 16px) !important;
    }
}



@media screen and (max-width: 350px) {
    .multiple-videos-section {
        width: calc(41.5% + 16px) !important;
    }
}



@media screen and (max-width: 320px) {
    .multiple-videos-section {
        width: calc(37.5% + 16px) !important;
    }
}