.center1 {
    width: 40%;
}

.job-interview-type-heading {
    font-size: 30px;
}

.select-job {
    font-size: 18px;
    padding-right: 20px;
    font-weight: 600;
    /* margin-top: 20px; */
}


.interview-type-label {
    color: #4a4a4a
}

.outline {
    padding: 10px;
}

.css-cu8ibo {
    width: 93%;
}

.job-selection-header {
    padding: 14px 30px;
    display: flex;
    justify-content: space-between;
}

.job-selection-header h2 {
    font-weight: 500;
}

.jsh-divider {
    border-top: 1px solid #d1d1d1;
    margin: 14px 0;
}

.labelModify.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused,
.labelModify.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    background: white !important;
    padding-right: 10px !important;
}

.candidate-list-data-grid .MuiDataGrid-columnHeader.MuiDataGrid-columnHeader--alignCenter.MuiDataGrid-columnHeaderCheckbox {
    height: 60px;
}

.main-div-select-job .css-u9osun.Mui-focused {
    background: white !important;
    padding: 0 12px !important;
}

.hidden-icon {
    display: none !important;
}

@media screen and (max-width:1536px) {
    .job-selection-box-sm {
        margin-right: 13px;
    }
}




@media screen and (max-width:900px) {
    .global-navigation-button {
        display: none;
    }

    .hidden-icon {
        display: block !important;
        position: fixed;
        right: '0px';
        bottom: '2px';
        height: '150px' !important;
        width: '150px' !important;
    }
}


@media screen and (min-width: 800px) {
    .interview-type-selector-sm {
        margin-left: 20px;
    }
}

@media screen and (max-width: 900px) {
    .interview-type-selector-sm {
        padding-left: 50px;
        margin-top: 70px;
    }
}

@media screen and (min-width: 290px) {

    .drop {

        padding-left: 30px;
        /* margin-top: 50px; */
    }
}

@media screen and (max-width: 900px) {

    .download-btn {
        /* width: 100%; */
        display: flex;
        width: '200%';
        justify-content: flex-end;

    }
}

@media screen and (max-height: 640px) {
    .globalButton {
        /* background-color: #e03333 */
        /* margin-bottom: -200%; */
        margin-top: -35px;
        /* position: relative; */

    }
}


/* .dx-gridbase-container {
    margin-left: 50px;
} */

/* 
.dx-command-select.dx-editor-cell.dx-editor-inline-block.dx-cell-focus-disabled {
    background-color: red;
    background: red;
    pointer-events: none;
    cursor: default;
}
.dx-widget.dx-checkbox.dx-select-checkbox.dx-datagrid-checkbox-size.dx-state-disabled{
  background-color: green;
    pointer-events: none;
    cursor: default;
} */

/* .dx-widget.dx-visibility-change-handler{
margin-left: -45px;
} */
/* 
.dx-overlay-content .dx-popup-normal .dx-resizable .dx-popup-flex-height{
    height: 250px;
} */
/* 

.dx-field-value.dx-overlay-content .dx-popup-normal .dx-resizable .dx-popup-flex-height{
    height: 400px !important;

} */


/* .dx-popup-content{
    height: 400px !important;
} */

/* .dropdown-job-selector .dx-datagrid.dx-gridbase-container{
    height: 350px!important;
} */
.dropdown-job-selector-DG{
  height: 409px !important;
    width: 352px;
    margin-left: -20px;
    padding: 0px;
    margin-bottom: -5px;
    margin-top: -8px;
}
.main-dropDownBox{
    width: 320px !important;
}
.spanClassCreateInterView {
    background-color: rgb(0, 116, 184);
    padding: 1px 9px;
    margin-right: 6px;
    border-radius: 50%;
  }
  .spanClassCreateInterView1 {
    background-color: rgb(173, 173, 173);
    padding: 1px 9px;
    margin-right: 6px;
    border-radius: 50%;
  }
  