.admin-logo{
    width: 70%;
    height: 50%;
}

.hitech-logo {
    width: 110px;
    height: 27px;
}

.hitech-logo img {
    width: 100%;
    height: 100%;
}

.info-box {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    width: 100%;
    top: 60px;
    padding: 0 13px;
}

.inner-info-box {
    background: #ffffff;
    height: 135px;
    width: 200px;
    box-shadow: 4px 0px 18px -4px #c9c9c9;
    color: black;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.info-box-body {
    display: flex;
    align-items: center;
}

.info-box-body-left {
    padding: 0px 8px 0 15px;
}

.info-box-body-right {
    color: grey;
}

.user-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #54667a;
    font-size: 14px;
    margin:10px;
    height: 25px;
    width: auto;
    padding:5px;
    border: 1px solid #bfbdbd;
}

.user-info-active-arrow{
    padding: 0!important;
    margin-left: -5px!important;
}
.info:hover{
    background-color: #efefef; 
    cursor: pointer;
}