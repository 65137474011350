.mail-template-preview{
    height: 72vh;
    overflow-y: scroll;
    border: 1px solid #dbd8d8;
    margin-top: 5px;
    padding-left: 10px
}

.row-border{
    margin: 20px 0;
    padding: 0 10px;
}

.nav-btn-inner-email-template-override{
    margin-top: 104px !important;
}

