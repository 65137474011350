@media screen and (max-width: 1400px) {
    
    .Ques-list .css-1nxmd3h-MuiListItem-root.Mui-selected{
        background-color: #4d94c0;
        color: white;
    }
    .card_info{
     overflow-x: scroll;
     padding-left: 10px;
     padding-right: 10px;  
        
    }
}
.interview-preview-back-btn:hover{
    background-color: #6998e4!important;
}
.interview-preview-back-btn{
    cursor: pointer;

}
.font{
    font-size: 16px;
}