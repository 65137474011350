.dx-datagrid-action {
  background-color: rgb(255, 255, 255);
  height: 44px;
}

/* .dx-command-select {
    background-color: rgb(248, 248, 248);

}  */

.dx-datagrid-action {
  /* background-color: #636c72; */
  height: 32px;
  color: #fff;
}

/* .MuiBox-root css-zxdg2z {


    margin-top: 50px;
} */
/* 
.dx-cell-focus-disabled {
    background-color: #636c72;
    color: rgb(255, 255, 255);
} */
.css-zxdg2z {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  width: 100%;
  padding: 24px 0px 24px 24px;
  margin-right: 20px;
}

.dx-pager .dx-page-sizes .dx-selection,
.dx-pager .dx-pages .dx-selection {
  border-radius: 50%;
  padding: 0 !important;
  background: #3aa1f0 !important;
  color: #fff !important;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 23px;
}
/* background: #3AA1F0;
    color: #fff; */

.dx-pager .dx-page-sizes .dx-page-size {
  border-radius: 50%;
  padding: 0 !important;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 23px;
}

.dx-datagrid-rowsview
  .dx-select-checkboxes-hidden
  > tbody
  > tr
  > td
  > .dx-select-checkbox {
  display: inline-block;
}

/* .dx-datagrid .dx-row > td {
  padding: 4px;
} */

.dx-widget {
  font-size: 13.5px;
}

.dx-datagrid {
  color: rgb(31, 29, 29);
}

/* .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
    padding: 0;
    width: 10px;
    min-width: 10px;
    max-width: 10px;
} */

/* .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-select {
  padding: 0;
  min-width: 25px;
  max-width: 25px;
} */

/* 
.dx-scrollable-native.dx-scrollable-both, .dx-scrollable-native.dx-scrollable-both > .dx-scrollable-wrapper > .dx-scrollable-container, .dx-scrollable-native.dx-scrollable-both > div > .dx-scrollable-wrapper > .dx-scrollable-container {
    -ms-touch-action: pan-y pan-x;
    touch-action: pan-y pan-x;
    float: none;
    overflow-x: auto;
    overflow-y: auto;
} */
.dx-datagrid-headers .dx-datagrid-table .dx-row.dx-header-row td,
.dx-header-row {
  background: #636c72;
}

.dx-toolbar.dx-widget.dx-visibility-change-handler.dx-collection.dx-popup-title {
  background-color: #3aa1f0;
  color: #fff;
}
/* 
.dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated.dx-fixed-columns.dx-last-row-border {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(75vh - 100px) !important;
  min-height: inherit;
  max-height: inherit;
} */

/* .container-datafield.dx-widget.dx-visibility-change-handler {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(75vh - 100px) !important;
  min-height: inherit;
  max-height: inherit;
} */

.pull-right {
  position: relative;
  top: 0px;
  width: 100%;
  /* margin-left: 55%; */
  /* float: right !important; */
  display: flex;
  justify-content: flex-end;
}

/* .pull-right-child{
   
 } */
.redspan1 {
  background-color: red;
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.redspan2 {
  background-color: green;
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.redspan3 {
  background-color: blue;
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.redspan4 {
  background-color: #ffa100;
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.redspan5 {
  background-color: #aa6d6b;
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.redspan6 {
  background-color: rgb(255 46 46 / 56%);
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
