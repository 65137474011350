/* .disp-error-alert {
    position: absolute;
    width: 30%;
    height: 100%;
    left: 38%;
    top: 11%;
    
} */
.disp-error-alert {
    position: absolute;
    /* width: 100%;
    height: 100%; */
    width: 100%;
    top: 11%;
    display: flex;
    justify-content: center;
    left: 10px;
}

.close-icon-err-alert {
    position: absolute;
    right: 2%;
    top: 25%;
    cursor: pointer;
}

.disp-error-alert-inner {
    position: relative;
    box-shadow: 5px 6px 14px -1px #c2b9b9;
}

.success-alert {
    z-index: 1301;
}

.template-update-snackbar {
    bottom: 0px !important;
    left: 0px !important;
    position: relative !important;
}