.required-field::before {
  content: "*";
  color: red;
}

.formfieldandtextfield {
  min-width: 550px !important;
  margin: "5px 0px" !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  margin-left: 0px;
}

.save-button {
  display: flex;
  bottom: -63px;
  left: 1287px;
}

@media screen and (max-width: 1629px) {
  .save-button {
    display: flex;
    bottom: -61px;
    left: 1046px;
  }
}

@media screen and (max-width: 1460px) {
  .save-button {
    display: flex;
    bottom: -61px;
    left: 920px;
  }
}

@media screen and (max-width: 1305px) {
  .save-button {
    display: flex;
    bottom: -61px;
    left: 802px;
  }
}

@media screen and (max-width: 1184px) {
  .save-button {
    display: flex;
    bottom: -61px;
    left: 708px;
  }
}

@media screen and (max-width: 1090px) {
  .save-button {
    display: flex;
    bottom: -61px;
    left: 643px;
  }
}

@media screen and (max-width: 1020px) {
  .save-button {
    display: flex;
    bottom: -61px;
    left: 590px;
  }
}

@media screen and (max-width: 960px) {
  .save-button {
    display: flex;
    bottom: -61px;
    left: 545px;
  }
}

@media screen and (max-width: 899px) {
  .save-button {
    display: flex;
    bottom: -61px;
    left: 8px;
  }

  .ats-to-local-down-icon {
    -ms-transform: rotate(90deg);
    /* IE 9 */
    transform: rotate(90deg);
  }
}

.ats-to-local-down-icon {
  margin-top: 260px;
  cursor: pointer;
}

.spanClass1 {
  background-color: rgb(0, 116, 184);
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.spanClass2 {
  background-color: rgb(173, 173, 173);
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.legendLable{
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 13px;
    padding-right: 10px !important;
    outline: none;
    box-sizing: border-box;
    cursor: default;
    color: #54667a;
  }
  
 .pull-right{
    position: relative;
    top: 0px;
    width: 100%;
    /* margin-left: 55%; */
    /* float: right !important; */
  display: flex;
  justify-content: flex-end;
   }