body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}



/* .css-f3jnds-MuiDataGrid-columnHeaders {
  background: #e0e0e0;
} */

.main-div-select-job {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.nav-btn-inner-sm-screen {
  bottom: 79px !important;
}
@media screen and (max-width: 870px) {
.candidate-list-main-div-inner {
  /* margin-left: 0 !important; */
  width: 91% !important;
  display: flex;
  justify-content: center;
  height: 51vh !important;
  margin: 20px;
  margin-left: 20px !important;
}
}

@media screen and (max-height: 730px) {
  .candidate-list-main-div-inner {
    /* margin-left: 0 !important; */
    width: 91% !important;
    display: flex;
    justify-content: center;
    height: 47vh !important;
    margin: 20px;
    margin-left: 20px !important;
  }
  }


@media screen and (max-height: 800px){
  .create-interview-main-div {
    height: 69vh !important;
    
  }

  .candidate-list-main-div {
    height: 71vh !important;
  }

  .job-interview-type-heading {
    font-size: 24px;
  }

  .candidate-list-m-d-header {
    font-size: 24px;
  }

  .download-btn {
    width: 70% !important;
  }

  .candidate-list-main-div-inner {
    /* margin-left: 0 !important; */
    width: 97% !important;
    display: flex;
    justify-content: center;
    height: 57vh !important;
    margin: 20px;
    margin-left: 20px !important;
  }


  .candidate-list-main-d-i-child {
    width: 100% !important;
  }

  .timezone-selector-div {
    margin: 24px 0 !important;
  }

  .text-editor {
    height: 555px !important;
  }

  .mail-template-preview {
    height: 69vh !important;
  }

  .interview-schedule-btn {
    bottom: 84px !important;
    width: 434px !important;
  }

  .interview-schedule-btn1 {
    font-size: 11px !important;
  }

  .main-div-select-job {
    padding: 0 15px;
  }

  .page-context-error {
    width: 50% !important;
  }

  .interview-build-heading {
    font-size: 24px;
  }

  .question-set-container {
    height: 66vh !important;
  }

  .question-box-right {
    font-size: 18px;
  }

  .question-text h5 {
    font-size: 16px;
  }

  .interview-invitation-head {
    font-size: 21px !important;
  }

  .timeline-input-grid {
    max-width: 20% !important;
  }
}


@media screen and (max-height: 760px) {
  #nav-btn-inner {
    bottom: 74px !important;
  }
}