/* .action {
    height: 130px;
    width: 120px;
    position: fixed;
    margin-top: 190px;
    /* background-color: aliceblue; */
/* background-color: white;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.action-child {
    height: 33%;
    width: 100%;
    display: inline-flex;
    justify-content: space-evenly;
    padding: 10px 0px 0px 0px;
    border:1px solid #bfbdbd;
}
.action-child:hover{
    transform: scale(1.06);    
}
.box-size{
    height: 637px;
    width: 100%;
}
.search_bar{
    display: flex;
    width: 100%;
    justify-content: flex-end;
    margin: 5px;
    margin-top: -10px;
}
@media screen  and (max-width: 1536px){
    .box-size{
        height: 530px;
        width: 100%;
    }
} */
.clean {
  cursor: pointer;
  border: 1px solid rgb(232, 232, 232);
  padding: 8px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
}
.clean:hover {
  background-color: rgb(239, 239, 239);
}

.dx-datagrid-headers {
  color: #ffffff !important;
}
.dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated.dx-fixed-columns.dx-last-row-border{

  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(75vh - 100px) !important;
  min-height: inherit;
  max-height: inherit;
}
/* .dx-datagrid-rowsview.dx-datagrid-nowrap.dx-last-row-border.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-simulated.dx-fixed-columns  */

/* .Main-dataGrid-Base.dx-datagrid.dx-gridbase-container.dx-datagrid-borders{
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  height: calc(100vh - 140px) !important;
  min-height: inherit;
  max-height: inherit;
} */

/* @media screen and (max-width: 1536px) {
    .dx-gridbase-container {
        display:
         -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        height: calc(100vh - -7px) !important;
        min-height: inherit;
        max-height: inherit;
    }
}
 */
.legendLable{
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 13px;
  padding-right: 10px !important;
  outline: none;
  box-sizing: border-box;
  cursor: default;
  color: #54667a;
}
 span[title="Reschedule"]{
  display: inline-block!important;
 }

 .pull-right{
  position: relative;
  top: 0px;
  width: 100%;
  /* margin-left: 55%; */
  /* float: right !important; */
display: flex;
justify-content: flex-end;
 }

 /* .pull-right-child{
   
 } */
.redspan1{
  background-color: red;
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.redspan2{
  background-color: green;
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.redspan3{
  background-color: blue;
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.redspan4{
  background-color: #ffa100;
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.redspan5{
  background-color: #aa6d6b;
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}
.redspan6{
  background-color:rgb(255 46 46 / 56%);
  padding: 1px 9px;
  margin-right: 6px;
  border-radius: 50%;
}