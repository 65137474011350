.preloader-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 100px 0;
}


.preloader-icon-dashboard{
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
}