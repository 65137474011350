.leftBox {
    width: 39%;
    box-shadow: 4;
    border-width: 2px;

}

input:focus {
    outline: none;
}

.left-box-scrollable-child {
    overflow-x: hidden;
    overflow-y: auto;
    height: 562px;
    height:100%;
    padding-bottom: 78px;
}


.rightBox {
    width: 58%;
    /* height: 70vh; */
    box-shadow: 4;
    border-width: 2px;


}

.question-box {
    height: 68vh;
    overflow: hidden;
    box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px, rgb(10 37 64 / 35%) 0px -2px 6px 0px inset
}

h1 {
    display: block;
    font-size: 2em;
    margin-block-start: 0.35em;
    margin-block-end: 0.35em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

h2 {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.30em;
    margin-block-end: 0.30em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    margin-top: 0.30em;
}


.MuiBox-root .css-10vp4q9 {
    background-color: white;
}

.css-cddhqs {
    background-color: white;
}


.QuestionnairiesBox {
    width: 100%;
    height: 10vh;
    border: 0.5px solid #c4c4c4;
}

.QuestionBox {
    width: 100%;

    border: 0.5px solid #c4c4c4;

    /* cursor: pointer; */
}


.question-box-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
}

.question-box-wrapper:hover {
    background-color: #ececec;
}

/* @media screen and (max-width: 1536px) and (min-width: 900px) { */


.question-box-left {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px 0 16px;
    overflow-x: auto;





}

.question-box-right {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px 0 16px;
}

/* } */
.QuestionBox-inner {
    width: 100%;

    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* overflow-x: scroll; */
}

/* @media screen and (max-width: 1536px) and (min-width: 900px) { */

.QuestionBox-inner-left {
    display: flex;
    justify-content: center;

}

/* } */
.question-text {
    padding: 0 20px;
}

.question-text h5 {
    font-size: 18px;
}

.QuestionBox-inner-right {
    display: flex;
    justify-content: center;
    align-items: center;

}

.qi-right-utility {
    padding: 0 0 0 11px;
}

.c-pointer {
    cursor: pointer;
}

.question-box-divider {
    width: 95%;
    height: 1px;
    background: #b7b7b7;
}


.bottom-timer-panel {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0;
    background: #ebebeb94;
}

.bt-panel-inner {
    padding: 0px 58px;
}

.bt-panel-inner-child {
    font-size: 21px;
    font-weight: 500;
}

.bt-panel-inner-child.time {
    font-size: 20px;
    color: #7f7f7f;
}

.search-bar-add-que {
    width: 350px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 10px 0 0;
}


.search-icon-rm {
    margin: 0 !important;
}

.search-bar-add-que.ques-list {
    width: 100%;
    justify-content: space-evenly;
}

.search-bar-add-que.ques-list .css-1um0yrm {
    width: 85%;
}

.scroll-position-fixed {
    position: fixed;
}

.search-bar-add-que .MuiBox-root.css-1343qlg {
    background: white;
}

.questionnarire-delete {
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ques-count {
    display: inline-block;
    padding-right: 10px;
}

.QuestionBox.enabled-editing-border {
    border: 2px solid #03a9f4;
}

/* media query */


@media screen and (min-width: 1381px) and (max-width: 1536px) {
    .search-bar-add-que {
        width: 220px;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 3px 0 0;
    }

    .question-box-right {
        font-size: 19px;
    }
}

@media screen and (min-width: 1190px) and (max-width: 1380px) {
    .search-bar-add-que {
        width: 140px;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

    }

    .question-box-right {
        font-size: 19px;
    }
}

@media screen and (min-width: 1053px) and (max-width: 1190px) {

    .search-bar-add-que {
        width: 137px;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

@media screen and (min-width: 900px) and (max-width: 1053px) {
    .search-bar-add-que {
        width: 110px;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 0;

    }
}

@media screen and (min-width: 1537px) and (max-width: 1678px) {
    .search-bar-add-que {
        width: 289px;
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 0;

    }
}

@media screen and (max-width: 1190px) {
    .text_h2 {
        display: block;
        font-size: 1.2em;
        margin-block-start: 0.3em;
        margin-block-end: 0.3em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        margin-top: 0.3em;
    }
}


.draggable {
    /* opacity: 0.5;
     transform: scale(0.9); */
     /* opacity: 0.8 */
     /* background: #ddd;
  border: 1px solid #ccc;
  box-sizing: border-box;
  transition: 0.25s; */
}

/* .dragging{
    background: violet;
} */

.draggable:active {
	/* animation: drag_style .1s; */
}

.draggable.dragging {
    /* background-color: navy;
    border-radius: 50%; */
  }


/* 
@keyframes drag_style {
    0% {background-color: #fc0; color:#000;}
    99% {background-color: #fc0; color:#000;}
    100% {background-color: #ccc;}
} */


