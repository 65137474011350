.icon {
  margin-left: 18px;
  padding-bottom: 10px;
}

.header p {
  display: block;
  margin-block-start: 0.1em;
  margin-block-end: 0.2em;
  margin-inline-start: 30px;
  margin-inline-end: 0px;
}

.header h3 {
  display: block;
  font-size: 0.85em;
  margin-block-start: 0.2em;
  margin-block-end: 0.1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.center {
  display: inline-flex;
  align-items: center;
}

.header h5 {
  display: block;
  font-size: 0.83em;
  margin-block-start: 0.25em;
  margin-block-end: 0.25em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.box_head {
  display: flex;
  width: 100%;
}

.head_icon {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.loadercenter {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 300px;
}

.grid-size {
  height: 650px;
  width: 100%;
  /* background-color: #e8fcfb; */
  /* box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px, rgb(0 0 0 / 30%) 0px 30px 60px -30px, rgb(10 37 64 / 35%) 0px -2px 6px 0px inset */
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1536px) {
  .grid-size {
    height: 450px;
    width: 100%;
  }
}

/* @media screen and (min-width:600px){ */
/* .pagination{
    position: fixed;
    bottom: 100px;
    right: 50px;
}
} */
@media screen and (max-width: 1400px) {
  .viewJobCard {
    overflow-x: scroll;
  }
}

@media screen and (max-width: 1300px) {
  .view-job-search-bar {
    width: 40% !important;
  }
}

@media screen and (max-width: 992px) {
  .view-job-search-bar {
    width: 50% !important;
  }
}

@media screen and (max-width: 800px) {
  .view-job-search-bar {
    width: 60% !important;
  }
}

@media screen and (max-width: 767px) {
  .view-job-search-bar {
    width: 224px !important;
  }
}

@media screen and (max-width: 650px) {
  .view-job-search-bar {
    width: 200px !important;
  }
}

@media screen and (max-width: 580px) {
  .view-job-search-bar {
    width: 160px !important;
  }

  .view-job-search-bar-input input::placeholder {
    font-size: 12px;
  }
  .view-job-search-bar {
    height: 45px !important;
  }
}

@media screen and (max-width: 535px) {
  .view-job-search-bar {
    width: 140px !important;
  }

  .view-job-search-bar-input input::placeholder {
    font-size: 12px;
  }
}

@media screen and (max-width: 539px) {
  .all-jobs-heading {
    font-size: 16px !important;
    margin-top: 14px !important;
  }

  .view-job-search-bar {
    height: 42px !important;
  }
}

@media screen and (max-width: 535px) {
  .view-job-search-bar-input-btn {
    padding: 5px !important;
  }
}

@media screen and (max-width: 1440px) {
  .view-job-search-bar-input {
    font-size: 13px !important;
  }
}

@media screen and (max-width: 1050px) {
  .view-job-search-bar {
    width: 50% !important;
  }
}
@media screen and (max-width: 900px) {
    .view-job-search-bar {
      width: 60% !important;
    }
  }

  .legendLable{
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 13px;
    padding-right: 10px !important;
    outline: none;
    box-sizing: border-box;
    cursor: default;
    color: #54667a;
  }
  .pull-right{
    position: relative;
    top: 0px;
    width: 100%;
    /* margin-left: 55%; */
    /* float: right !important; */
  display: flex;
  justify-content: flex-end;
   }
   .span1{
    background-color: green;
    padding: 1px 9px;
    margin-right: 6px;
    border-radius: 50%;
  }
  .span2{
    background-color: red;
    padding: 1px 9px;
    margin-right: 6px;
    border-radius: 50%;
  }  