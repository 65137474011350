.rdw-editor-main {
    border: 1px solid #e9e9e9;
}

.css-1wa3eu0-placeholder {
    display: none;
}

.timezone-selector {
    margin: -29px 0 0 0;
    font-size: 12px !important;
    background: white;
    padding: 0 10px 0 5px !important;
    top: 6px !important;
}


/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper{
    max-height: calc(50% - 35px);
     overflow: scroll;
} */

.all-day-checkbox .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
    margin-top: 0px;
}

.text-editor {
    margin: 25px 0px 0px;
    height: 600px;
    overflow-y: scroll;
    border-bottom: 1px solid #e9e9e9;
}

.rdw-editor-main {
    border: 1px solid #e9e9e9;
    border-bottom: none;
}


.nav-btn-inner-override {
    /* position: relative !important;
    right: -9px !important;
    bottom: 22px !important;
    z-index: 0 !important; */
    position: relative !important;
    z-index: 0 !important;
    margin-top: 108px !important;
    right: 20px !important;
    float: right !important;
}


.nav-btn-wrapper-override {
    margin-top: -100px !important;
}

.css-1s2u09g-control {
    min-height: 50px !important;
}

.arrow-down-icon {
    display: none;
}

.m-interviewDayTimeDiff-chkbox {
    margin-left: 21px !important;
    margin-top: 10px
}

#react-select-3-listbox {
    position: absolute;
    z-index: 9;
}

#text-editor-main::-webkit-scrollbar {
    display: none;
}



/* media query */

@media screen and (max-width: 900px) {
    .sm-to-down {
        margin-top: 50px !important;
    }

    .arrow-sign {
        position: absolute;
        width: 100%;
        top: 65px;
    }

    .arrow-right-icon {
        display: none;
    }

    .arrow-down-icon {
        display: block;
    }

    .all-day-checkbox {
        margin-left: 20px;
        margin-top: 27px;
    }

    .all-day-checkbox.chkbox2 {
        margin: 0;
    }

    .timezone-selector-div {
        margin: 20px 0 !important;
    }

    .interview-invitation-head {
        font-size: 18px;
    }
}



@media screen and (max-width: 598px) {
    .xs-to-down {
        margin-top: 33px !important;
    }

    .xs-to-down.middle {
        margin-top: 50px !important;
    }

    .arrow-sign {
        top: 159px;
    }
}